'use client'

import Spinner from '@/components/ui/Spinner'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs'
import { useRouter } from 'next/navigation'

const schema = yup.object().shape({
	email: yup.string().email('Invalid email address').required('Email is required'),
	password: yup.string().required('Password is required'),
})

type FormData = {
	email: string
	password: string
}

const Login = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [showPassword, setShowPassword] = useState(false)

	const authOptions = {
		auth: {
			autoRefreshToken: true,
			persistSession: true,
			detectSessionInUrl: false,
		},
	}

	const supabase = createClientComponentClient({ options: authOptions })
	const router = useRouter()

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema),
	})

	const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		setShowPassword((prev) => !prev)
	}

	const handleFormSubmit = async (data: FormData) => {
		setIsLoading(true)
		setErrorMessage('')

		const { email, password } = data

		try {
			const response = await fetch(`/api/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email, password }),
			})

			if (response.ok) {
				const data = await response.json()
				if (data.userRecord) {
					const { error } = await supabase.auth.signInWithPassword({ email, password })
					if (error) {
						setErrorMessage(error.message)
					} else {
						router.push('/')
					}
					setIsLoading(false)
				}
			} else {
				const error = await response.json()
				setErrorMessage(
					error.message ||
						'Something went wrong. Please reach out to support@productlaunchformula.com',
				)
			}
		} catch (error) {
			setErrorMessage(errorMessage || 'An unexpected error occurred.')
			setIsLoading(false)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div className='bg-white w-full md:max-w-md lg:max-w-full md:mx-0 md:w-1/2 xl:w-1/3 h-[400px] md:h-screen px-6 lg:px-16 xl:px-12 sm:flex items-center justify-center z-10'>
			<div className='w-full h-100'>
				<h1 className='text-xl md:text-2xl font-bold leading-tight mt-12 text-gray-800'>
					Log in to your Launchy account
				</h1>
				{errorMessage && <p className='mt-6 text-red-500'>{errorMessage}</p>}
				<form className='mt-6' onSubmit={handleSubmit(handleFormSubmit)} method='POST'>
					<div className='space-y-4'>
						<div className='relative'>
							<svg
								fill='none'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								viewBox='0 0 24 24'
								stroke='currentColor'
								className='h-5 w-5 absolute text-gray-400 left-3 top-1/2 transform -translate-y-1/2'
							>
								<path d='M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207' />
							</svg>
							<input
								type='email'
								placeholder='Enter your email address'
								className='w-full px-4 py-3 pl-10 rounded-lg bg-gray-100 border focus:border-blue-500 focus:bg-white focus:outline-none'
								{...register('email')}
								autoComplete='off'
							/>
						</div>
						{errors.email && <p className='my-2 text-red-500 text-sm'>{errors.email.message}</p>}
						<div className='relative'>
							<svg
								className='h-5 w-5 absolute text-gray-400 left-3 top-1/2 transform -translate-y-1/2'
								fill='none'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								viewBox='0 0 24 24'
								stroke='currentColor'
							>
								<path d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z' />
							</svg>
							<input
								type={showPassword ? 'text' : 'password'}
								placeholder='Enter your password'
								className='w-full px-4 py-3 pl-10 rounded-lg bg-gray-100 border focus:border-blue-500 focus:bg-white focus:outline-none'
								{...register('password')}
							/>
							<button
								className='h-5 w-5 absolute text-gray-400 right-3 top-1/2 transform -translate-y-1/2'
								onClick={togglePasswordVisibility}
							>
								<span className='sr-only'>Toggle password visibility</span>
								{showPassword ? (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='size-6'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88'
										/>
									</svg>
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='size-6'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
										/>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
										/>
									</svg>
								)}
							</button>
						</div>
						{errors.password && (
							<p className='my-2 text-red-500 text-sm'>{errors.password.message}</p>
						)}
						<button
							type='submit'
							disabled={isSubmitting || isLoading}
							className={`flex px-5 py-3 mt-3 text-center font-medium text-white rounded-md w-full items-center justify-center ${
								isSubmitting || isLoading
									? 'bg-gray-400'
									: 'bg-blue-600 hover:bg-blue-700 focus:bg-blue-700'
							}`}
						>
							{isLoading ? (
								<div className='w-full flex justify-center'>
									<Spinner />
								</div>
							) : (
								'Log In'
							)}
						</button>
					</div>
					<div className='flex items-center justify-between mt-2'>
						<a
							className='text-sm text-blue-500 hover:underline'
							target='_blank'
							href='/login/reset-password'
						>
							Forgot password?
						</a>
					</div>
				</form>
			</div>
		</div>
	)
}

export default Login
